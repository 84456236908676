<main
  class="relative flex h-screen w-screen items-center justify-center bg-[url(/assets/images/bg.jpeg)] bg-cover md:h-screen"
>
  <div
    class="absolute bottom-0 left-0 right-0 top-0 z-0 bg-alpha-blue opacity-30"
  ></div>

  <div
    class="relative flex flex-col justify-center bg-white px-16 pb-10 pt-16 md:h-auto md:max-w-3xl md:rounded-2xl md:shadow-lg"
  >
    <app-alerts />
    <router-outlet />
  </div>
</main>
