import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIResponse } from '../interfaces/api.interface';
import {
  Job,
  JobCreate,
  JobSearchParams,
  JobUpdate,
} from '../interfaces/job.interface';
import { queryParamString } from '../utils/query-param-string';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  public constructor(private readonly api: ApiService) {}

  public create(organisationId: string, data: JobCreate): Observable<Job> {
    return this.api.post<Job, JobCreate>(
      `organisations/${organisationId}/orders`,
      data,
    );
  }

  public get(organisationId: string, jobId: string): Observable<Job> {
    return this.api.get<Job>(
      `organisations/${organisationId}/orders/${encodeURIComponent(jobId)}`,
    );
  }

  public search(
    organisationId: string,
    params?: JobSearchParams,
  ): Observable<APIResponse<Job[]>> {
    return this.api.get<APIResponse<Job[]>>(
      queryParamString(`organisations/${organisationId}/orders`, params),
    );
  }

  public update(
    organisationId: string,
    jobId: string,
    data: JobUpdate,
  ): Observable<Job> {
    return this.api.put<Job, JobUpdate>(
      `organisations/${organisationId}/orders/${jobId}`,
      data,
    );
  }

  // public delete(
  //   organisationId: string,
  //   jobId: string,
  // ): Observable<void> {
  //   return this.api.delete(
  //     `organisations/${organisationId}/orders/${jobId}`,
  //   );
  // }
}
