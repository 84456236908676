import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  catchError,
  from,
  map,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { OptionService } from '../../services/option.service';
import { handleHttpError } from '../../utils/api';
import { OptionActions } from '../actions/option.actions';
import { selectActiveOrganisationId } from '../selectors/user.selector';

@Injectable()
export class OptionEffects {
  public loadDropOffLocation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionActions.getDropOffLocation),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      switchMap(([{ id }, organisationId]) =>
        from(this.service.getDropOffLocation(organisationId, id)).pipe(
          map((payload) =>
            OptionActions.getDropOffLocationSuccess({
              data: payload,
            }),
          ),
          catchError((error) =>
            of(OptionActions.getDropOffLocationFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public getDropOffLocationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionActions.getDropOffLocationFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public loadDropOffLocations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionActions.loadDropOffLocations),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_action, organisationId]) =>
        from(this.service.getDropOffLocations(organisationId)).pipe(
          map((payload) =>
            OptionActions.loadDropOffLocationsSuccess({
              data: payload.items,
            }),
          ),
          catchError((error) =>
            of(OptionActions.loadDropOffLocationsFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public loadDropOffLocationsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionActions.loadDropOffLocationsFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public loadSampleSuites$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionActions.loadSampleSuites),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_action, organisationId]) =>
        from(this.service.getSampleSuites(organisationId)).pipe(
          map((payload) =>
            OptionActions.loadSampleSuitesSuccess({
              data: payload.items,
            }),
          ),
          catchError((error) =>
            of(OptionActions.loadSampleSuitesFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public loadSampleSuitesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionActions.loadSampleSuitesFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public loadSampleTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionActions.loadSampleTypes),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_action, organisationId]) =>
        from(this.service.getSampleTypes(organisationId)).pipe(
          map((payload) =>
            OptionActions.loadSampleTypesSuccess({
              data: payload.items,
            }),
          ),
          catchError((error) =>
            of(OptionActions.loadSampleTypesFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public loadSampleTypesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionActions.loadSampleTypesFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public loadSampleMatrices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionActions.loadSampleMatrices),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_action, organisationId]) =>
        from(this.service.getSampleMatrices(organisationId)).pipe(
          map((payload) =>
            OptionActions.loadSampleMatricesSuccess({
              data: payload.items,
            }),
          ),
          catchError((error) =>
            of(OptionActions.loadSampleMatricesFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public loadSampleMatricesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionActions.loadSampleMatricesFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly service: OptionService,
    private readonly store: Store,
  ) {}
}
