import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiOptionItem, APIResponse } from '../interfaces/api.interface';
import { DropOffLocation, SampleSuite } from '../interfaces/option.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class OptionService {
  public constructor(private readonly api: ApiService) {}

  public getDropOffLocation(
    organisationId: string,
    id: string,
  ): Observable<DropOffLocation> {
    return this.api.get<DropOffLocation>(
      `organisations/${organisationId}/options/drop-off-locations/${id}`,
    );
  }

  public getDropOffLocations(
    organisationId: string,
  ): Observable<APIResponse<DropOffLocation[]>> {
    return this.api.get<APIResponse<DropOffLocation[]>>(
      `organisations/${organisationId}/options/drop-off-locations`,
    );
  }

  public getSampleSuites(
    organisationId: string,
  ): Observable<APIResponse<SampleSuite[]>> {
    return this.api.get<APIResponse<SampleSuite[]>>(
      `organisations/${organisationId}/options/suites`,
    );
  }

  public getSampleTypes(
    organisationId: string,
  ): Observable<APIResponse<string[]>> {
    return this.api.get<APIResponse<string[]>>(
      `organisations/${organisationId}/options/types`,
    );
  }

  public getSampleMatrices(
    organisationId: string,
  ): Observable<APIResponse<ApiOptionItem[]>> {
    return this.api.get<APIResponse<ApiOptionItem[]>>(
      `organisations/${organisationId}/options/matrices`,
    );
  }
}
