import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { LOQATE_API_URL } from '../../environments/environment';
import { Find, Loqate, Retrieve } from '../interfaces/loqate.interface';
import { HTTPService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class LoqateService extends HTTPService {
  public constructor() {
    super(LOQATE_API_URL);
  }

  public async find(search: string): Promise<Loqate<Find>> {
    return await firstValueFrom(
      this.get<Loqate<Find>>(`find/${encodeURIComponent(search)}?countries=GB`),
    );
  }

  public async findContainer(
    search: string,
    id: string,
  ): Promise<Loqate<Find>> {
    return await firstValueFrom(
      this.get<Loqate<Find>>(
        `find/${encodeURIComponent(
          search,
        )}/container/${encodeURIComponent(id)}?countries=GB`,
      ),
    );
  }

  public async retrieve(id: string): Promise<Loqate<Retrieve>> {
    return await firstValueFrom(
      this.get<Loqate<Retrieve>>(`retrieve/${encodeURIComponent(id)}`),
    );
  }
}
