import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { AlertsComponent } from '../../../components/alerts/alerts.component';

@Component({
  standalone: true,
  templateUrl: './auth-layout.component.html',
  imports: [RouterOutlet, AlertsComponent],
})
export class AuthLayoutComponent {}
