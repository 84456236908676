import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  catchError,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
} from 'rxjs';

import { OrganisationUser } from '../../interfaces/organisation.interface';
import { OrganisationService } from '../../services/organisation.service';
import { UserService } from '../../services/user.service';
import { handleHttpError } from '../../utils/api';
import { UsersActions } from '../actions/users.actions';

@Injectable()
export class UsersEffects {
  public search$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.search),
      switchMap(({ params }) =>
        from(this.service.search(params, true)).pipe(
          map(({ items }) => UsersActions.searchSuccess({ users: items })),
          catchError((error) => of(UsersActions.searchFailure({ error }))),
        ),
      ),
    ),
  );

  public searchFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.searchFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.get),
      switchMap((request) =>
        from(this.service.get(request.organisationId, request.userId)).pipe(
          map((user: OrganisationUser) => UsersActions.getSuccess({ user })),
          catchError((error) => of(UsersActions.getFailure({ error }))),
        ),
      ),
    ),
  );

  public getFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.getFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public passwordReset$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.resetPassword),
      switchMap(({ userId }) =>
        from(this.service.passwordReset(userId)).pipe(
          map(() => UsersActions.resetPasswordSuccess()),
          catchError((error) =>
            of(UsersActions.resetPasswordFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public removeFromOrganisation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.removeFromOrganisation),
      switchMap(({ organisationId, userId, admin }) =>
        from(
          this.organisationService.removeUser(organisationId, userId, admin),
        ).pipe(
          mergeMap((user: OrganisationUser) => [
            UsersActions.removeFromOrganisationSuccess({ user }),
          ]),
          catchError((error) =>
            of(UsersActions.removeFromOrganisationFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly service: UserService,
    private readonly organisationService: OrganisationService,
  ) {}
}
