import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, switchMap } from 'rxjs';

import { API_URL, LOQATE_API_URL } from '../../environments/environment';
import { CognitoService } from '../services/cognito.service';

@Injectable({
  providedIn: 'root',
})
export class CognitoTokenInterceptor implements HttpInterceptor {
  constructor(private readonly cognitoService: CognitoService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return from(this.cognitoService.currentSession()).pipe(
      switchMap((session) => {
        if (session.tokens?.accessToken) {
          if (request.url.includes(LOQATE_API_URL)) {
            return next.handle(
              request.clone({
                setHeaders: {
                  Authorization: `Bearer ${session.tokens.idToken}`,
                },
              }),
            );
          } else if (request.url.includes(API_URL)) {
            return next.handle(
              request.clone({
                setHeaders: {
                  Authorization: `Bearer ${session.tokens.accessToken}`,
                },
              }),
            );
          } else {
            return next.handle(request);
          }
        } else {
          return next.handle(request);
        }
      }),
    );
  }
}
