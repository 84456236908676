import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export abstract class HTTPService {
  private readonly http: HttpClient = inject(HttpClient);

  protected constructor(private readonly baseUrl: string) {}

  public get<T>(url: string): Observable<T> {
    return this.http.get<T>(this.buildUrl(url));
  }

  public patch<T, U>(url: string, body: U): Observable<T> {
    return this.http.patch<T>(this.buildUrl(url), body);
  }

  public post<T, U>(url: string, body: U): Observable<T> {
    return this.http.post<T>(this.buildUrl(url), body);
  }

  public put<T, U>(url: string, body?: U): Observable<T> {
    return this.http.put<T>(this.buildUrl(url), body ?? {});
  }

  public delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(this.buildUrl(url));
  }

  private buildUrl(path: string): string {
    return `${this.baseUrl}${path}`;
  }
}
