import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { catchError, combineLatest, map, of, skipWhile } from 'rxjs';

import { UserActions } from '../store/actions/user.actions';
import { CognitoState } from '../store/reducers/cognito.reducer';
import { UserState } from '../store/reducers/user.reducer';

export const adminGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);

  return combineLatest([
    store.pipe(select('user')),
    store.pipe(select('cognito')),
  ]).pipe(
    skipWhile(([userState, cognitoState]: [UserState, CognitoState]) => {
      if (cognitoState.pending) {
        return true;
      }

      if (!userState.pending && !userState?.user && cognitoState.accessToken) {
        store.dispatch(UserActions.me());
        return true;
      }

      if (userState.error) {
        throw new Error(
          typeof userState.error === 'string'
            ? userState.error
            : JSON.stringify(userState.error),
        );
      }

      if (!userState.pending && !userState?.user?.id) {
        throw new Error('No user returned');
      }

      // If the user is an admin user, redirect them to the admin/organisations URL.
      if (!userState.pending && userState?.user?.profile?.group === 'USER') {
        router.navigateByUrl('/organisation-details', { replaceUrl: true });
      }

      return !!userState?.pending;
    }),
    map(([state]) => {
      if (state.error) {
        throw new Error(
          typeof state.error === 'string'
            ? state.error
            : JSON.stringify(state.error),
        );
      } else {
        return true;
      }
    }),
    catchError(() => of(router.parseUrl('/auth/account-missing'))),
  );
};
