import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  catchError,
  from,
  map,
  Observable,
  of,
  retry,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { ERROR_PAGE_URL } from '../../pages/not-found-error/not-found-error.page';
import { SampleService } from '../../services/sample.service';
import { handleHttpError } from '../../utils/api';
import { SampleActions } from '../actions/sample.actions';
import { selectActiveOrganisationId } from '../selectors/user.selector';

@Injectable()
export class SampleEffects {
  public createSample$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SampleActions.createSample),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      switchMap(([action, organisationId]) => {
        return from(
          this.service.createSample(
            organisationId,
            action.jobId,
            action.sample,
          ),
        ).pipe(
          map((sample) =>
            SampleActions.createSampleSuccess({
              sample,
            }),
          ),
          catchError((error) =>
            of(
              SampleActions.createSampleFailure({
                error,
                sample: {
                  client: organisationId,
                  orderId: action.jobId,
                  ...action.sample,
                },
              }),
            ),
          ),
        );
      }),
    ),
  );

  public createSampleFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SampleActions.createSampleFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public updateSample$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SampleActions.updateSample),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      switchMap(([action, organisationId]) => {
        return from(
          this.service.updateSample(
            organisationId,
            action.jobId,
            action.sampleId,
            {
              ...action.sample,
            },
          ),
        ).pipe(
          map((sample) =>
            SampleActions.updateSampleSuccess({
              sample,
            }),
          ),
          catchError((error) =>
            of(
              SampleActions.updateSampleFailure({
                error,
                sample: action.sample,
              }),
            ),
          ),
        );
      }),
    ),
  );

  public updateSampleFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SampleActions.updateSampleFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public loadSample$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SampleActions.loadSample),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      switchMap(([action, organisationId]) =>
        from(
          this.service.getSample(organisationId, action.jobId, action.sampleId),
        ).pipe(
          retry(2),
          map((sample) =>
            SampleActions.loadSampleSuccess({
              sample,
            }),
          ),
          catchError((error) => of(SampleActions.loadSampleFailure({ error }))),
        ),
      ),
    ),
  );

  public loadSampleFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SampleActions.loadSampleFailure),
        tap(async () => await this.router.navigateByUrl(`/${ERROR_PAGE_URL}`)),
      ),
    { dispatch: false },
  );

  public loadSamples$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SampleActions.loadSamples),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      switchMap(([action, organisationId]) =>
        from(this.service.getSamples(organisationId, action.jobId, false)).pipe(
          retry(2),
          map((payload) =>
            SampleActions.loadSamplesSuccess({
              samples: payload.items,
            }),
          ),
          catchError((error) =>
            of(SampleActions.loadSamplesFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public loadSamplesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SampleActions.loadSamplesFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public adminLoadSample$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SampleActions.adminLoadSample),
      switchMap((action) =>
        from(
          this.service.getSample(
            action.orgId,
            action.jobId,
            action.sampleId,
            true,
          ),
        ).pipe(
          retry(2),
          map((sample) =>
            SampleActions.loadSampleSuccess({
              sample,
            }),
          ),
          catchError((error) =>
            of(SampleActions.adminLoadSampleFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public adminLoadSampleFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SampleActions.adminLoadSampleFailure),
        tap(
          async () =>
            await this.router.navigateByUrl(`/admin/${ERROR_PAGE_URL}`),
        ),
      ),
    { dispatch: false },
  );

  public adminLoadSamples$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SampleActions.adminLoadSamples),
      switchMap((action) =>
        from(this.service.getSamples(action.orgId, action.jobId, true)).pipe(
          retry(2),
          map((payload) =>
            SampleActions.loadSamplesSuccess({
              samples: payload.items,
            }),
          ),
          catchError((error) =>
            of(SampleActions.loadSamplesFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public adminLoadSamplesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SampleActions.adminLoadSamplesFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly service: SampleService,
    private readonly store: Store,
  ) {}
}
