import { Routes } from '@angular/router';

import { jobsRoutes } from './dashboard/jobs-and-tests/jobs.routes';
import { JOBS_AND_TESTS_PAGE_URL } from './dashboard/jobs-and-tests/jobs-and-tests.page';
import { ORGANISATION_DETAILS_PAGE_URL } from './dashboard/organisation-details/organisation-details.page';
import { PERSONAL_DETAILS_PAGE_URL } from './dashboard/personal-details/personal-details.page';
import { USER_MANAGEMENT_PAGE_URL } from './dashboard/user-management/user-management.page';
import { userManagementRoutes } from './dashboard/user-management/user-management.routes';
import { errorRoutes } from './not-found-error/not-found-error.page';

export const authenticatedRoutes: Routes = [
  {
    path: ORGANISATION_DETAILS_PAGE_URL,
    loadComponent: () =>
      import('./dashboard/organisation-details/organisation-details.page').then(
        (m) => m.OrganisationDetailsPage,
      ),
    title: 'Dashboard | Alpha Scientific',
  },
  {
    path: USER_MANAGEMENT_PAGE_URL,
    loadChildren: () => userManagementRoutes,
    title: 'User management | Alpha Scientific',
  },
  {
    path: JOBS_AND_TESTS_PAGE_URL,
    loadChildren: () => jobsRoutes,
    title: 'Jobs & tests | Alpha Scientific',
  },
  {
    path: PERSONAL_DETAILS_PAGE_URL,
    loadComponent: () =>
      import('./dashboard/personal-details/personal-details.page').then(
        (m) => m.PersonalDetailsPage,
      ),
    title: 'Personal details | Alpha Scientific',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ORGANISATION_DETAILS_PAGE_URL,
  },
  ...errorRoutes,
];
