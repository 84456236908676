import { Action, createReducer, on } from '@ngrx/store';

import { Find, Retrieve } from '../../interfaces/loqate.interface';
import { LoqateActions } from '../actions/loqate.action';

export interface LoqateState {
  error: unknown;
  find: Find[];
  pending: boolean;
  retrieve: Retrieve | null;
  searchTerm: string | null;
}

export const initialState: LoqateState = {
  error: null,
  find: [],
  pending: false,
  retrieve: null,
  searchTerm: null,
};

const loqateReducer = createReducer(
  initialState,
  on(LoqateActions.clearState, (): LoqateState => initialState),
  on(
    LoqateActions.find,
    (state): LoqateState => ({ ...state, pending: true, retrieve: null }),
  ),
  on(
    LoqateActions.findSuccess,
    (state, { data }): LoqateState => ({
      ...state,
      find: data.Items,
      pending: false,
      searchTerm: data.searchTerm ?? null,
    }),
  ),
  on(
    LoqateActions.findFailure,
    (state, { error }): LoqateState => ({
      ...state,
      error,
      pending: false,
      retrieve: null,
    }),
  ),
  on(
    LoqateActions.findContainer,
    (state): LoqateState => ({ ...state, pending: true }),
  ),
  on(
    LoqateActions.findContainerSuccess,
    (state, { payload, containerId }): LoqateState => {
      const index = state.find.findIndex(({ Id }) => Id === containerId);
      const find = [...state.find];

      find.splice(index, 1, ...payload.Items);

      return {
        ...state,
        find,
        pending: false,
        searchTerm: payload.searchTerm ?? null,
      };
    },
  ),
  on(
    LoqateActions.findContainerFailure,
    (state, { error }): LoqateState => ({ ...state, error, pending: false }),
  ),
  on(
    LoqateActions.retrieve,
    (state): LoqateState => ({ ...state, pending: true }),
  ),
  on(
    LoqateActions.retrieveSuccess,
    (state, { payload }): LoqateState => ({
      ...state,
      retrieve: payload.Items[0],
      pending: false,
    }),
  ),
  on(
    LoqateActions.retrieveFailure,
    (state, { error }): LoqateState => ({ ...state, error, pending: false }),
  ),
);

export const reducer = (state: LoqateState | undefined, action: Action) =>
  loqateReducer(state, action);
