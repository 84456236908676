import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry, throwError, timer } from 'rxjs';

@Injectable()
export class RetryHttpErrorsInterceptor implements HttpInterceptor {
  private readonly RETRY_COUNT = 2;
  private readonly RETRY_DELAY = 200;
  private readonly RETRY_ERROR_CODES = [401, 403];

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry({
        count: this.RETRY_COUNT,
        delay: (
          error: HttpErrorResponse,
          retryAttempt: number,
        ): Observable<number> => {
          if (
            retryAttempt > this.RETRY_COUNT ||
            !this.RETRY_ERROR_CODES.includes(error.status)
          ) {
            return throwError(() => error);
          }
          return timer(retryAttempt * this.RETRY_DELAY);
        },
      }),
    );
  }
}
