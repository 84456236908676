import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import {
  AuthSession,
  AuthUser,
  confirmResetPassword,
  ConfirmResetPasswordInput,
  confirmSignUp,
  ConfirmSignUpInput,
  ConfirmSignUpOutput,
  fetchAuthSession,
  getCurrentUser,
  resendSignUpCode,
  ResendSignUpCodeOutput,
  resetPassword,
  ResetPasswordInput,
  ResetPasswordOutput,
  signIn,
  SignInInput,
  SignInOutput,
  signOut,
  signUp,
  SignUpInput,
  SignUpOutput,
  updatePassword,
  UpdatePasswordInput,
} from 'aws-amplify/auth';

import {
  COGNITO_USER_POOL_ID,
  COGNITO_USER_WEB_CLIENT_ID,
} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  public constructor() {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: COGNITO_USER_POOL_ID,
          userPoolClientId: COGNITO_USER_WEB_CLIENT_ID,
        },
      },
    });
  }

  public async userAttributes(): Promise<AuthUser> {
    return await getCurrentUser();
  }

  public async currentSession(): Promise<AuthSession> {
    return await fetchAuthSession();
  }

  public async signIn(credentials: SignInInput): Promise<SignInOutput> {
    await this.signOut();
    return await signIn(credentials);
  }

  public async signUp(credentials: SignUpInput): Promise<SignUpOutput> {
    await this.signOut();
    return await signUp(credentials);
  }

  public async signOut(): Promise<void> {
    return await signOut();
  }

  public async resetPassword(
    credentials: ResetPasswordInput,
  ): Promise<ResetPasswordOutput> {
    return await resetPassword(credentials);
  }

  public async resetPasswordWithCode(
    credentials: ConfirmResetPasswordInput,
  ): Promise<void> {
    await confirmResetPassword(credentials);
  }

  public async updatePassword(credentials: UpdatePasswordInput): Promise<void> {
    await updatePassword(credentials);
  }

  public async activate(
    credentials: ConfirmSignUpInput,
  ): Promise<ConfirmSignUpOutput> {
    await this.signOut();
    return await confirmSignUp(credentials);
  }

  public async resendSignUp(username: string): Promise<ResendSignUpCodeOutput> {
    return await resendSignUpCode({ username });
  }
}
