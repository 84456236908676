import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIResponse } from '../interfaces/api.interface';
import { OrganisationUser } from '../interfaces/organisation.interface';
import {
  User,
  UserProfile,
  UserSearchParams,
} from '../interfaces/user.interface';
import { queryParamString } from '../utils/query-param-string';
import { ApiService } from './api.service';

export interface ProfilePayload {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public constructor(private readonly api: ApiService) {}

  public search(
    params?: UserSearchParams,
    admin = false,
  ): Observable<APIResponse<OrganisationUser[]>> {
    return this.api.get<APIResponse<OrganisationUser[]>>(
      queryParamString(admin ? `admin/users` : `users`, params),
    );
  }

  public get(
    organisationId: string,
    userId: string,
  ): Observable<OrganisationUser> {
    const path = `admin/organisations/${organisationId}/users/${encodeURIComponent(userId)}`;

    return this.api.get<OrganisationUser>(path);
  }

  public me(): Observable<User> {
    return this.api.get<User>('me');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public acceptInvite(data: { code: string }): Observable<any> {
    return this.api.post('me/invite/accept', data);
  }

  public getProfile(): Observable<UserProfile> {
    return this.api.get<UserProfile>('me/profile');
  }

  public createProfile(data: ProfilePayload): Observable<UserProfile> {
    return this.api.post<UserProfile, ProfilePayload>('me/profile', data);
  }

  public updateProfile(data: Partial<ProfilePayload>): Observable<UserProfile> {
    return this.api.put<UserProfile, Partial<ProfilePayload>>(
      'me/profile',
      data,
    );
  }

  public passwordReset(userId: string): Observable<object> {
    return this.api.post(
      'admin/users/' + encodeURIComponent(userId) + '/password-reset',
      {},
    );
  }
}
