import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AlertState, selectAllAlert } from "../reducers/alert.reducer";

export const selectAlertState = createFeatureSelector<AlertState>('alerts');

export const selectAllAlerts = createSelector(selectAlertState, selectAllAlert);

export const selectAlertByType = (type: string) =>
  createSelector(selectAlertState, (state) => Object.values(state.entities).filter(alert => alert?.type === type));
