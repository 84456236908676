<main
  class="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-alpha-blue"
>
  <div
    class="flex h-full w-full flex-col items-center justify-center rounded-none bg-white p-14 text-center md:h-auto md:w-auto md:rounded-2xl md:py-20"
  >
    <h1 class="text-3xl font-medium">404 - Not found</h1>
    <p class="mt-2 text-lg text-alpha-gray">
      The requested resource could not be found.
    </p>

    <button
      aria-disabled="true"
      (click)="navigateToDashboard()"
      (keydown.enter)="navigateToDashboard()"
      class="mt-6 text-alpha-blue"
    >
      Back to dashboard
    </button>
  </div>
</main>
