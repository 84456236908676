import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  catchError,
  from,
  map,
  Observable,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { Organisation } from '../../interfaces/organisation.interface';
import { ERROR_PAGE_URL } from '../../pages/not-found-error/not-found-error.page';
import { OrganisationService } from '../../services/organisation.service';
import { handleHttpError } from '../../utils/api';
import { OrganisationActions } from '../actions/organisation.actions';
import { selectActiveOrganisationId } from '../selectors/user.selector';

@Injectable()
export class OrganisationEffects {
  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.get),
      switchMap((request) =>
        from(this.service.get(request.id)).pipe(
          map((data: Organisation) => OrganisationActions.getSuccess({ data })),
          catchError((error) => of(OrganisationActions.getFailure({ error }))),
        ),
      ),
    ),
  );

  public getSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganisationActions.getSuccess),
        tap(() => this.store.dispatch(OrganisationActions.getUsers())),
      ),
    { dispatch: false },
  );

  public getFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.getFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public getUserOverview$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.getUserOverview),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_action, organisationId]) =>
        from(this.service.userOverview(organisationId)).pipe(
          map((data) => OrganisationActions.getUserOverviewSuccess({ data })),
          catchError((error) =>
            of(OrganisationActions.getUserOverviewFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public getOrderOverview$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.getOrderOverview),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_action, organisationId]) =>
        from(this.service.orderOverview(organisationId)).pipe(
          map((data) => OrganisationActions.getOrderOverviewSuccess({ data })),
          catchError((error) =>
            of(OrganisationActions.getOrderOverviewFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public getUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.getUser),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      switchMap(([action, organisationId]) =>
        from(this.service.getUser(organisationId, action.id)).pipe(
          map((data) => OrganisationActions.getUserSuccess({ data })),
          catchError((error) =>
            of(OrganisationActions.getUserFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public getUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganisationActions.getUserFailure),
        tap(async () => await this.router.navigateByUrl(`/${ERROR_PAGE_URL}`)),
      ),
    { dispatch: false },
  );

  public getAllUsers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.getAllUsers),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_action, organisationId]) =>
        from(this.service.getAllUsers(organisationId)).pipe(
          map(({ items }) =>
            OrganisationActions.getAllUsersSuccess({
              data: items,
            }),
          ),
          catchError((error) =>
            of(OrganisationActions.getAllUsersFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public getAllUsersFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.getAllUsersFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public getUsers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.getUsers),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_action, organisationId]) =>
        from(this.service.getUsers(organisationId)).pipe(
          map((data) =>
            OrganisationActions.getUsersSuccess({
              data: data.items,
            }),
          ),
          catchError((error) =>
            of(OrganisationActions.getUsersFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public getUsersFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.getUsersFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public inviteResend$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.inviteResend),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      switchMap(([action, organisationId]) =>
        from(this.service.resendInvite(organisationId, action)).pipe(
          map((user) => OrganisationActions.inviteResendSuccess({ user })),
          catchError((error) =>
            of(OrganisationActions.inviteResendFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public inviteResendFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.inviteResendFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public inviteUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.inviteUser),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      switchMap(([action, organisationId]) =>
        from(this.service.inviteUser(organisationId, action.user)).pipe(
          map((user) => OrganisationActions.inviteUserSuccess({ user })),
          catchError((error) =>
            of(OrganisationActions.inviteUserFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public removeUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.removeUser),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      switchMap(([action, organisationId]) =>
        from(this.service.removeUser(organisationId, action.id)).pipe(
          map((user) => OrganisationActions.removeUserSuccess({ user })),
          catchError((error) =>
            of(OrganisationActions.removeUserFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public removeUserFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.removeUserFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public updateUserRole$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.updateUserRole),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      switchMap(([action, organisationId]) =>
        from(
          this.service.updateUserRole(organisationId, action.userId, {
            role: action.role,
          }),
        ).pipe(
          map((user) => OrganisationActions.updateUserRoleSuccess({ user })),
          catchError((error) =>
            of(OrganisationActions.updateUserRoleFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public updateUserRoleFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.updateUserRoleFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly service: OrganisationService,
    private readonly store: Store,
  ) {}
}
