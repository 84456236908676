import { Injectable } from '@angular/core';

import { API_URL } from '../../environments/environment';
import { HTTPService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends HTTPService {
  public constructor() {
    super(API_URL);
  }
}
