import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, from, map, Observable, of, switchMap, tap } from 'rxjs';

import { Organisation } from '../../interfaces/organisation.interface';
import { ERROR_PAGE_URL } from '../../pages/not-found-error/not-found-error.page';
import { OrganisationService } from '../../services/organisation.service';
import { handleHttpError } from '../../utils/api';
import { OrganisationsActions } from '../actions/organisations.actions';

@Injectable()
export class OrganisationsEffect {
  public search$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.search),
      switchMap(({ params }) =>
        from(this.service.search(params, true)).pipe(
          map(({ items }) =>
            OrganisationsActions.searchSuccess({ organisations: items }),
          ),
          catchError((error) =>
            of(OrganisationsActions.searchFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public searchFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.searchFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.get),
      switchMap((request) =>
        from(this.service.get(request.id, true)).pipe(
          map((organisation: Organisation) =>
            OrganisationsActions.getSuccess({ organisation }),
          ),
          catchError((error) => of(OrganisationsActions.getFailure({ error }))),
        ),
      ),
    ),
  );

  public getFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganisationsActions.getFailure),
        tap(
          async () =>
            await this.router.navigateByUrl(`/admin/${ERROR_PAGE_URL}`),
        ),
      ),
    { dispatch: false },
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.create),
      switchMap((action) =>
        from(this.service.create(action.payload)).pipe(
          map((payload) =>
            OrganisationsActions.createSuccess({ payload, user: action.user }),
          ),
          catchError((result: HttpErrorResponse) =>
            of(OrganisationsActions.createFailure({ error: result })),
          ),
        ),
      ),
    ),
  );

  public createSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.createSuccess),
      map(({ payload, user }) =>
        OrganisationsActions.inviteUser({ user, organisationId: payload.id }),
      ),
    ),
  );

  public createFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.createFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.update),
      switchMap(({ id, payload }) =>
        from(this.service.update(id, payload, true)).pipe(
          map((payload) => OrganisationsActions.updateSuccess({ payload })),
          catchError((result: HttpErrorResponse) =>
            of(OrganisationsActions.updateFailure({ error: result })),
          ),
        ),
      ),
    ),
  );

  public updateFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.updateFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public inviteUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.inviteUser),
      switchMap(({ organisationId, user }) =>
        from(this.service.inviteUser(organisationId, user, true)).pipe(
          map((user) => OrganisationsActions.inviteUserSuccess({ user })),
          catchError((error) =>
            of(OrganisationsActions.inviteUserFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly service: OrganisationService,
  ) {}
}
