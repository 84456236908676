import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { catchError, map, of, skipWhile } from 'rxjs';

import { CognitoActions } from '../store/actions/cognito.actions';
import { CognitoState } from '../store/reducers/cognito.reducer';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store<{ cognito: CognitoState }>);

  store.dispatch(CognitoActions.currentSession());

  return store.pipe(
    select('cognito'),
    skipWhile((cognito) => {
      if (cognito.error) {
        throw new Error(
          typeof cognito.error === 'string'
            ? cognito.error
            : JSON.stringify(cognito.error),
        );
      }
      if (!cognito.pending && (!cognito.idToken || !cognito.accessToken)) {
        throw new Error();
      }
      return !cognito.idToken;
    }),
    map((cognito) => {
      if (cognito.error) {
        throw new Error(
          typeof cognito.error === 'string'
            ? cognito.error
            : JSON.stringify(cognito.error),
        );
      } else {
        return true;
      }
    }),
    catchError(() => of(router.parseUrl('/auth/login'))),
  );
};
