import { Routes } from '@angular/router';

export const userManagementRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./user-management.page').then((m) => m.UserManagementPage),
    title: 'User management - Alpha Scientific',
  },
  {
    path: ':id',
    loadComponent: () =>
      import('./user-details/user-details.page').then((m) => m.UserDetailsPage),
    title: 'User management - Alpha Scientific',
  },
  {
    path: '**',
    redirectTo: '',
  },
];
