import { Routes } from '@angular/router';

import { PERSONAL_DETAILS_PAGE_URL } from '../dashboard/personal-details/personal-details.page';
import { errorRoutes } from '../not-found-error/not-found-error.page';
import { ADMIN_JOBS_PAGE_URL } from './jobs/jobs.page';
import { ADMIN_JOB_PAGE_URL } from './jobs/pages/job/job.page';
import { ADMIN_SAMPLE_PAGE_URL } from './jobs/pages/sample/sample.page';
import { ADMIN_ORGANISATIONS_PAGE_URL } from './organisations/organisations.page';
import { ADMIN_ORGANISATION_DETAILS_PAGE_URL } from './organisations/pages/organisation-details/organisation-details.page';
import { ADMIN_ORGANISATION_USER_DETAILS_PAGE_URL } from './organisations/pages/organisation-details/pages/user-details/user-details.page';
import { ADMIN_USER_DETAILS_PAGE_URL } from './users/pages/user-details/user-details.page';
import { ADMIN_USERS_PAGE_URL } from './users/users.page';

export const adminRoutes: Routes = [
  // ORGANISATIONS
  {
    path: ADMIN_ORGANISATIONS_PAGE_URL,
    loadComponent: () =>
      import('./organisations/organisations.page').then(
        (m) => m.OrganisationsPage,
      ),
    title: 'Organisations | Alpha Scientific',
  },
  {
    path: ADMIN_ORGANISATION_DETAILS_PAGE_URL,
    loadComponent: () =>
      import(
        './organisations/pages/organisation-details/organisation-details.page'
      ).then((m) => m.OrganisationDetailsPage),
    title: 'Organisation details | Alpha Scientific',
  },
  // ORGANISATION / USER DETAILS
  {
    path: ADMIN_USER_DETAILS_PAGE_URL,
    loadComponent: () =>
      import(
        './organisations/pages/organisation-details/pages/user-details/user-details.page'
      ).then((m) => m.AdminOrganisationUserDetailsPage),
    title: 'User details | Alpha Scientific',
  },
  // USERS
  {
    path: ADMIN_USERS_PAGE_URL,
    loadComponent: () => import('./users/users.page').then((m) => m.UsersPage),
    title: 'Users | Alpha Scientific',
  },
  // USERS / USER DETAILS
  {
    path: ADMIN_ORGANISATION_USER_DETAILS_PAGE_URL,
    loadComponent: () =>
      import('./users/pages/user-details/user-details.page').then(
        (m) => m.AdminUserDetailsPage,
      ),
    title: 'User details | Admin - Alpha Scientific',
  },
  // JOBS
  {
    path: ADMIN_JOBS_PAGE_URL,
    loadComponent: () => import('./jobs/jobs.page').then((m) => m.JobsPage),
    title: 'Job | Alpha Scientific',
  },
  // JOB
  {
    path: ADMIN_JOB_PAGE_URL,
    loadComponent: () =>
      import('./jobs/pages/job/job.page').then((m) => m.JobPage),
    title: ({ params }) =>
      `${params['jobId'] || 'Loading..'} | Alpha Scientific`,
  },
  // SAMPLE
  {
    path: ADMIN_SAMPLE_PAGE_URL,
    loadComponent: () =>
      import('./jobs/pages/sample/sample.page').then((m) => m.SamplePage),
    title: ({ params }) =>
      `${params['sampleId'] || 'Loading..'} | Alpha Scientific`,
  },
  // PERSONAL DETAILS
  {
    path: PERSONAL_DETAILS_PAGE_URL,
    loadComponent: () =>
      import('../dashboard/personal-details/personal-details.page').then(
        (m) => m.PersonalDetailsPage,
      ),
    title: 'Personal details | Alpha Scientific',
  },
  ...errorRoutes,
];
