import { Routes } from '@angular/router';

import { authGuard } from '../../guards/auth.guard';

export const authRoutes: Routes = [
  {
    path: 'account-missing',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./account-missing/account-missing.page').then(
        (m) => m.AccountMissingPage,
      ),
    title: 'Account missing - Alpha Scientific',
  },
  {
    path: 'activate/:email/:code',
    loadComponent: () =>
      import('./account-activation/account-activation.page').then(
        (m) => m.AccountActivationPage,
      ),
    title: 'Account activation  - Alpha Scientific',
  },
  {
    path: 'invitation',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./invitation/invitation.page').then((m) => m.InvitationPage),
    title: 'Invitation - Alpha Scientific',
  },
  {
    path: 'login',
    loadComponent: () => import('./login/login.page').then((m) => m.LoginPage),
    title: 'Login - Alpha Scientific',
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./register/register.page').then((m) => m.RegisterPage),
    title: 'Register - Alpha Scientific',
  },
  {
    path: 'request-password-reset',
    loadComponent: () =>
      import('./request-reset-password/request-reset-password.page').then(
        (m) => m.RequestResetPasswordPage,
      ),
    title: 'Request password reset - Alpha Scientific',
  },
  {
    path: 'reset-password/username/:username/code/:code',
    loadComponent: () =>
      import('./reset-password/reset-password.page').then(
        (m) => m.ResetPasswordPage,
      ),
    title: 'Password reset - Alpha Scientific',
  },
  {
    path: 'password-reset-success',
    loadComponent: () =>
      import('./reset-password-success/reset-password-success.page').then(
        (m) => m.RequestResetPasswordSuccessPage,
      ),
    title: 'Password reset successfully - Alpha Scientific',
  },
  {
    path: '**',
    redirectTo: 'auth/login',
  },
];
