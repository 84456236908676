import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, from, map, Observable, of, switchMap, tap } from 'rxjs';

import { ERROR_PAGE_URL } from '../../pages/not-found-error/not-found-error.page';
import { OrganisationService } from '../../services/organisation.service';
import { handleHttpError } from '../../utils/api';
import { OrganisationUsersActions } from '../actions/organisation-users.actions';

@Injectable()
export class OrganisationUsersEffects {
  public search$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUsersActions.search),
      switchMap(({ organisationId, params, admin }) =>
        from(this.service.getUsers(organisationId, params.status, admin)).pipe(
          map(({ items }) =>
            OrganisationUsersActions.searchSuccess({ users: items }),
          ),
          catchError((error) =>
            of(OrganisationUsersActions.searchFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public searchFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUsersActions.searchFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUsersActions.get),
      switchMap(({ organisationId, userId, admin }) =>
        from(this.service.getUser(organisationId, userId, admin)).pipe(
          map((user) => OrganisationUsersActions.getSuccess({ user })),
          catchError((error) =>
            of(OrganisationUsersActions.getFailure({ error, admin })),
          ),
        ),
      ),
    ),
  );

  public getFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganisationUsersActions.getFailure),
        tap(
          async ({ admin }) =>
            await this.router.navigateByUrl(
              `/${admin ? 'admin/' : ''}${ERROR_PAGE_URL}`,
            ),
        ),
      ),
    { dispatch: false },
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly service: OrganisationService,
  ) {}
}
