import { Action, createReducer, on } from '@ngrx/store';

import { User } from '../../interfaces/user.interface';
import { CognitoActions } from '../actions/cognito.actions';

export interface CognitoState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  pending: boolean;
  idToken: string | null;
  accessToken: string | null;
  userAttributes: User | null;
}

export const initialState: CognitoState = {
  error: null,
  pending: false,
  idToken: null,
  accessToken: null,
  userAttributes: null,
};

const cognitoReducer = createReducer(
  initialState,
  on(
    CognitoActions.activateAccount,
    (state): CognitoState => ({ ...state, error: null, pending: true }),
  ),
  on(
    CognitoActions.activateAccountSuccess,
    (state): CognitoState => ({ ...state, error: null, pending: false }),
  ),
  on(
    CognitoActions.activateAccountFailure,
    (state, { error }): CognitoState => ({ ...state, error, pending: false }),
  ),
  on(CognitoActions.clearState, (): CognitoState => initialState),
  on(
    CognitoActions.currentSession,
    (state): CognitoState => ({ ...state, error: null, pending: true }),
  ),
  on(
    CognitoActions.currentSessionSuccess,
    (state, { data }): CognitoState => ({
      ...state,
      error: false,
      pending: false,
      idToken: data.tokens?.idToken?.toString() ?? null,
      accessToken: data.tokens?.accessToken?.toString() ?? null,
    }),
  ),
  on(
    CognitoActions.currentSessionFailure,
    (state, { error }): CognitoState => ({ ...state, error, pending: false }),
  ),
  on(
    CognitoActions.forgotPassword,
    (state): CognitoState => ({ ...state, error: null, pending: true }),
  ),
  on(
    CognitoActions.forgotPasswordSuccess,
    (state): CognitoState => ({ ...state, error: null, pending: false }),
  ),
  on(
    CognitoActions.forgotPasswordFailure,
    (state, { error }): CognitoState => ({ ...state, error, pending: false }),
  ),
  on(
    CognitoActions.resetPassword,
    (state): CognitoState => ({ ...state, error: null, pending: true }),
  ),
  on(
    CognitoActions.resetPasswordSuccess,
    (state): CognitoState => ({ ...state, error: null, pending: false }),
  ),
  on(
    CognitoActions.resetPasswordFailure,
    (state, { error }): CognitoState => ({ ...state, error, pending: false }),
  ),
  on(
    CognitoActions.resetPasswordWithCode,
    (state): CognitoState => ({ ...state, error: null, pending: true }),
  ),
  on(
    CognitoActions.resetPasswordWithCodeSuccess,
    (state): CognitoState => ({ ...state, error: null, pending: false }),
  ),
  on(
    CognitoActions.resetPasswordWithCodeFailure,
    (state, { error }): CognitoState => ({ ...state, error, pending: false }),
  ),
  on(
    CognitoActions.updatePassword,
    (state): CognitoState => ({ ...state, error: null, pending: true }),
  ),
  on(
    CognitoActions.updatePasswordFailure,
    (state, { error }): CognitoState => ({ ...state, error, pending: false }),
  ),
  on(
    CognitoActions.signIn,
    (state): CognitoState => ({ ...state, error: null, pending: true }),
  ),
  on(
    CognitoActions.signInSuccess,
    (state): CognitoState => ({
      ...state,
      error: null,
      pending: false,
      idToken: null,
      accessToken: null,
    }),
  ),
  on(
    CognitoActions.signInFailure,
    (state, { error }): CognitoState => ({ ...state, error, pending: false }),
  ),
  on(
    CognitoActions.signUp,
    (state): CognitoState => ({ ...state, error: null, pending: true }),
  ),
  on(
    CognitoActions.signUpSuccess,
    (state): CognitoState => ({ ...state, error: null, pending: false }),
  ),
  on(
    CognitoActions.signUpFailure,
    (state, { error }): CognitoState => ({ ...state, error, pending: false }),
  ),
  on(
    CognitoActions.signOut,
    (state): CognitoState => ({ ...state, pending: true }),
  ),
  on(CognitoActions.signOutSuccess, (): CognitoState => ({ ...initialState })),
);

export const reducer = (
  state: CognitoState | undefined,
  action: Action,
): CognitoState => cognitoReducer(state, action);
