import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';

import { JobsService } from '../../services/jobs.service';
import { handleHttpError } from '../../utils/api';
import { JobUsersActions } from "../actions/job-users.actions";

@Injectable()
export class JobUsersEffects {
  public search$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(JobUsersActions.search),
      switchMap(({ params , admin}) =>
        from(this.service.searchUsers(params, admin)).pipe(
          map(({ items }) => {
            return JobUsersActions.searchSuccess({ users: items });
          }),
          catchError((error) =>
            of(JobUsersActions.searchFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public searchFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobUsersActions.searchFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly service: JobsService,
  ) {}
}
