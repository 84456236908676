import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIResponse } from '../interfaces/api.interface';
import {
  Sample,
  SampleCreate,
  SampleUpdate,
} from '../interfaces/sample.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SampleService {
  constructor(private readonly api: ApiService) {}

  public getSample(
    organisationId: string,
    orderId: string,
    sampleId: string,
    admin = false,
  ): Observable<Sample> {
    const path = admin
      ? `admin/organisations/${organisationId}/orders/${orderId}/samples/${sampleId}`
      : `organisations/${organisationId}/orders/${orderId}/samples/${sampleId}`;

    return this.api.get<Sample>(path);
  }

  public getSamples(
    organisationId: string,
    orderId: string,
    admin = false,
  ): Observable<APIResponse<Sample[]>> {
    const path = admin
      ? `admin/organisations/${organisationId}/orders/${orderId}/samples`
      : `organisations/${organisationId}/orders/${orderId}/samples`;

    return this.api.get<APIResponse<Sample[]>>(path);
  }

  public createSample(
    organisationId: string,
    orderId: string,
    sample: SampleCreate,
  ): Observable<Sample> {
    return this.api.post<Sample, SampleCreate>(
      `organisations/${organisationId}/orders/${orderId}/samples`,
      sample,
    );
  }

  public updateSample(
    organisationId: string,
    orderId: string,
    sampleId: string,
    sample: SampleUpdate,
  ): Observable<Sample> {
    return this.api.put<Sample, SampleUpdate>(
      `organisations/${organisationId}/orders/${orderId}/samples/${sampleId}`,
      sample,
    );
  }
}
