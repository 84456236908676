import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { AlertActions } from '../../store/actions/alert.action';
import { selectAllAlerts } from '../../store/selectors/alert.selectors';

@Component({
  selector: 'app-alerts',
  templateUrl: 'alerts.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class AlertsComponent implements OnDestroy {
  @Input()
  public type: 'error' | 'success' | 'info' | undefined;

  public readonly alerts$ = this.store.select(selectAllAlerts);

  public constructor(private readonly store: Store) {}

  public getAlertTitle(type: string): string {
    switch (type) {
      case 'error':
        return 'Error!';
      case 'warning':
        return 'Warning!';
      case 'success':
        return 'Success!';
      default:
        return 'Notice!';
    }
  }

  public closeAlert(id: string): void {
    this.store.dispatch(AlertActions.delete({ id }));
  }

  public ngOnDestroy(): void {
    this.store.dispatch(AlertActions.clearState());
  }
}
