import { Action, ActionReducer, MetaReducer } from '@ngrx/store';

import { CognitoActions } from './actions/cognito.actions';
import { CognitoEffects } from './effects/cognito.effects';
import { JobEffects } from './effects/job.effects';
import { JobUsersEffects } from "./effects/job-users.effects";
import { JobsEffects } from './effects/jobs.effects';
import { LoqateEffects } from './effects/loqate.effects';
import { OptionEffects } from './effects/option.effects';
import { OrganisationEffects } from './effects/organisation.effects';
import { OrganisationUsersEffects } from './effects/organisation-users.effects';
import { OrganisationsEffect } from './effects/organisations.effect';
import { SampleEffects } from './effects/sample.effect';
import { UserEffects } from './effects/user.effects';
import { UsersEffects } from './effects/users.effects';
import { AlertState, reducer as alertReducer } from './reducers/alert.reducer';
import {
  CognitoState,
  reducer as cognitoReducer,
} from './reducers/cognito.reducer';
import { JobState, reducer as jobReducer } from './reducers/job.reducer';
import { JobUsersState,reducer as jobUsersReducer} from './reducers/job-users.reducer'
import { JobsState, reducer as jobsReducer } from './reducers/jobs.reducer';
import {
  LoqateState,
  reducer as loqateReducer,
} from './reducers/loqate.reducer';
import {
  OptionState,
  reducer as optionReducer,
} from './reducers/option.reducer';
import {
  OrganisationState,
  reducer as organisationReducer,
} from './reducers/organisation.reducer';
import {
  OrganisationUsersState,
  reducer as organisationUsers,
} from './reducers/organisation-users.reducer';
import {
  OrganisationsState,
  reducer as organisationsReducer,
} from './reducers/organisations.reducer';
import {
  reducer as sampleReducer,
  SampleState,
} from './reducers/sample.reducer';
import { reducer as userReducer, UserState } from './reducers/user.reducer';
import { reducer as usersReducer, UsersState } from './reducers/users.reducer';

export interface AppState {
  cognito: CognitoState;
  job: JobState;
  jobs: JobsState;
  jobUsers: JobUsersState;
  loqate: LoqateState;
  organisation: OrganisationState;
  organisationUsers: OrganisationUsersState;
  organisations: OrganisationsState;
  option: OptionState;
  sample: SampleState;
  user: UserState;
  users: UsersState;
  alerts: AlertState;
}

// Root reducers
export const reducers = {
  cognito: cognitoReducer,
  job: jobReducer,
  jobs: jobsReducer,
  jobUsers: jobUsersReducer,
  loqate: loqateReducer,
  organisation: organisationReducer,
  organisationUsers: organisationUsers,
  organisations: organisationsReducer,
  option: optionReducer,
  sample: sampleReducer,
  user: userReducer,
  users: usersReducer,
  alerts: alertReducer,
};

// Root effects
export const effects = [
  CognitoEffects,
  JobEffects,
  JobUsersEffects,
  JobsEffects,
  LoqateEffects,
  OrganisationEffects,
  OrganisationUsersEffects,
  OrganisationsEffect,
  OptionEffects,
  SampleEffects,
  UserEffects,
  UsersEffects,
];

// Meta reducer for clearing the store on sign out
export const clearState =
  (reducer: ActionReducer<AppState>): ActionReducer<AppState> =>
  (state: AppState | undefined, action: Action): AppState => {
    if (
      action.type === CognitoActions.signOutSuccess.type ||
      action.type === CognitoActions.signIn.type
    ) {
      state = undefined;
    }

    return reducer(state, action);
  };
export const metaReducers: MetaReducer<AppState>[] = [clearState];
