import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { AlertsComponent } from '../../components/alerts/alerts.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

@Component({
  standalone: true,
  templateUrl: './layout.component.html',
  imports: [RouterOutlet, SidebarComponent, AlertsComponent],
})
export class LayoutComponent {}
