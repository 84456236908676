import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Alert } from "../../interfaces/alert.interface";
import { AlertActions } from "../actions/alert.action";

export type AlertState = EntityState<Alert>

const adapter: EntityAdapter<Alert> = createEntityAdapter<Alert>({
  selectId: (alert: Alert): string => alert.id,
});

export const initialState: AlertState = adapter.getInitialState({});

const AlertReducer = createReducer(
  initialState,
  on(AlertActions.clearState, (): AlertState => initialState),
  on(AlertActions.get, (state): AlertState => ({ ...state })),
  on(AlertActions.create, (state, { alert }) =>
    adapter.addOne(alert, state)
  ),
  on(AlertActions.delete, (state, { id }) =>
    adapter.removeOne(id, state)
  )
);

const { selectAll } = adapter.getSelectors();

export const selectAllAlert = selectAll;

export const reducer = (state: AlertState | undefined, action: Action) =>
  AlertReducer(state, action);
