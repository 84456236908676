export function queryParamString<T>(path: string, obj?: T): string {
  const params: string[] = [];

  if (obj) {
    Object.entries(obj).forEach(([key, value]) => {
      params.push(`${key}=${value}`);
    });
  }

  return `${path}${params.length ? '?' + params.join('&') : ''}`;
}
